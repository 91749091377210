import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1020.000000 295.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,295.000000) scale(0.100000,-0.100000)" >



<path d="M1337 2924 c-690 -107 -1196 -592 -1314 -1259 -21 -120 -21 -351 1
-472 79 -448 360 -810 792 -1019 61 -30 116 -54 122 -54 7 0 12 144 15 463 3
424 5 469 25 552 41 178 116 346 207 465 45 59 167 175 206 195 11 6 16 14 13
18 -4 4 -2 8 4 8 21 2 26 4 50 17 12 7 22 18 22 24 0 6 -9 3 -19 -7 -19 -16
-20 -16 -18 2 1 16 7 19 34 17 18 -1 35 2 38 7 4 5 -1 6 -9 3 -9 -3 -16 -3
-16 0 0 11 34 46 45 46 6 0 18 8 27 18 34 35 44 40 64 29 15 -8 17 -7 7 3 -15
17 82 65 232 115 l110 37 175 -4 c97 -3 186 -9 200 -16 14 -6 54 -20 90 -30
87 -27 140 -50 140 -62 0 -6 8 -10 18 -10 10 0 27 -7 38 -15 14 -11 51 -14
152 -15 l132 0 0 480 0 480 -747 -1 c-581 -1 -768 -4 -836 -15z"/>
<path d="M3350 1841 l0 -801 160 0 160 0 0 240 0 240 273 0 274 0 164 -240
164 -240 183 0 c100 0 182 3 182 6 0 3 -75 112 -167 242 -91 131 -175 249
-185 265 l-20 27 54 24 c74 33 170 108 214 167 20 27 48 82 62 122 21 60 26
90 26 182 0 127 -16 191 -70 281 -63 105 -136 163 -282 223 -118 49 -169 53
-699 58 l-493 5 0 -801z m1000 603 c83 -39 140 -101 169 -184 36 -105 32 -285
-9 -378 -28 -62 -101 -135 -164 -164 -49 -22 -62 -23 -363 -26 l-313 -3 0 391
0 391 318 -3 c297 -3 320 -5 362 -24z"/>
<path d="M4930 2635 c0 -2 123 -190 273 -417 150 -227 314 -476 365 -554 l92
-141 0 -241 0 -242 170 0 170 0 0 243 0 242 182 275 c99 151 264 400 365 553
100 153 183 280 183 283 0 2 -80 4 -178 4 l-177 0 -192 -292 c-106 -161 -225
-344 -265 -405 -40 -62 -80 -113 -88 -113 -12 0 -274 389 -509 757 l-34 53
-178 0 c-99 0 -179 -2 -179 -5z"/>
<path d="M7087 2383 c-59 -142 -179 -433 -267 -648 -89 -214 -186 -451 -217
-525 -31 -74 -60 -143 -65 -152 -8 -17 2 -18 155 -18 l164 0 74 195 74 195
439 -3 440 -2 79 -192 80 -193 155 0 c142 0 154 1 149 18 -4 15 -291 723 -555
1370 l-87 212 -256 0 -257 0 -105 -257z m543 -349 c91 -229 167 -420 168 -425
2 -5 -147 -9 -352 -9 -196 0 -356 2 -356 5 0 2 54 136 120 297 67 161 144 350
173 420 34 84 57 128 67 127 10 0 65 -127 180 -415z"/>
<path d="M8550 1840 l0 -800 160 0 160 0 0 655 c0 637 1 655 19 655 14 0 127
-167 442 -655 l424 -654 218 -1 217 0 0 800 0 800 -160 0 -160 0 0 -651 0
-650 -21 3 c-15 2 -134 175 -443 648 l-422 645 -217 3 -217 2 0 -800z"/>
<path d="M1979 1755 c-299 -48 -495 -151 -625 -326 -36 -49 -42 -65 -21 -53 7
4 9 3 4 -2 -5 -5 -14 -9 -20 -9 -16 0 -73 -136 -96 -231 -33 -132 -41 -268
-41 -705 l0 -419 353 2 c328 3 352 4 351 21 -1 9 -2 29 -3 43 0 14 -5 23 -10
20 -7 -5 -36 25 -93 97 -10 12 -22 27 -27 33 -14 17 -71 134 -65 134 3 0 -2 7
-10 16 -9 8 -14 20 -11 25 3 4 -1 22 -10 40 -9 17 -14 33 -11 37 3 3 2 10 -4
17 -5 6 -14 52 -21 103 -13 99 -2 238 25 317 8 22 20 60 27 85 7 25 19 52 26
61 7 8 13 23 13 32 0 9 4 17 9 17 5 0 11 8 15 18 17 49 181 226 251 270 49 32
145 82 145 76 0 -3 11 3 25 12 14 9 25 13 25 9 0 -4 6 -3 13 3 6 5 26 11 42
13 17 2 36 8 44 14 21 15 304 13 365 -3 28 -8 59 -15 68 -17 35 -7 137 -59
132 -67 -3 -4 5 -8 18 -8 15 0 30 -10 40 -27 17 -27 17 -21 18 164 l0 192
-342 1 c-189 0 -381 2 -428 5 -49 2 -121 -2 -171 -10z"/>
<path d="M7113 910 c-12 -5 -25 -21 -29 -36 -6 -22 -2 -32 20 -51 28 -24 54
-24 84 -1 20 17 14 65 -10 82 -25 18 -33 19 -65 6z"/>
<path d="M8613 910 c-45 -18 -41 -80 7 -100 25 -10 34 -10 55 4 33 21 34 68 3
90 -25 18 -33 19 -65 6z"/>
<path d="M8280 781 l0 -51 -35 0 c-34 0 -35 -1 -35 -40 0 -39 1 -40 35 -40
l35 0 1 -112 c0 -126 12 -172 50 -201 37 -27 106 -33 152 -13 38 15 38 15 23
45 -14 26 -19 29 -54 24 -64 -9 -72 8 -72 143 l0 114 55 0 55 0 0 40 0 40 -54
0 -55 0 -3 48 -3 47 -47 3 -48 3 0 -50z"/>
<path d="M3535 721 c-82 -20 -135 -73 -155 -153 -27 -106 33 -210 139 -242 64
-19 92 -20 144 -5 36 10 107 62 107 79 0 4 -15 17 -34 28 -31 18 -36 19 -47 6
-35 -42 -99 -54 -153 -29 -60 29 -85 118 -51 184 32 63 142 79 193 28 l24 -25
34 20 c19 11 34 24 34 28 0 4 -16 22 -35 38 -52 46 -123 61 -200 43z"/>
<path d="M3960 710 c-187 -64 -180 -328 9 -384 30 -9 64 -16 76 -16 53 0 131
33 166 70 105 110 56 291 -90 334 -69 20 -93 20 -161 -4z m146 -76 c42 -20 64
-59 64 -114 0 -101 -93 -158 -186 -114 -42 20 -64 59 -64 114 0 54 21 93 62
113 42 21 81 21 124 1z"/>
<path d="M4360 520 l0 -210 50 0 50 0 0 133 c0 149 7 168 67 193 45 19 98 9
123 -23 18 -23 20 -41 20 -164 l0 -139 50 0 50 0 0 128 c0 144 8 168 60 195
44 22 87 16 118 -19 21 -23 22 -34 22 -165 l0 -139 51 0 51 0 -4 153 c-4 178
-14 207 -88 245 -68 34 -151 25 -207 -22 l-28 -24 -28 24 c-59 51 -164 55
-231 9 l-36 -24 0 29 c0 29 -2 30 -45 30 l-45 0 0 -210z"/>
<path d="M5190 520 l0 -210 50 0 50 0 0 133 c0 149 7 168 67 193 45 19 98 9
123 -23 18 -23 20 -41 20 -164 l0 -139 50 0 50 0 0 128 c0 144 8 168 60 195
44 22 87 16 118 -19 21 -23 22 -34 22 -165 l0 -139 51 0 51 0 -4 153 c-4 170
-13 202 -71 237 -66 41 -168 34 -224 -14 l-28 -24 -28 24 c-60 51 -164 55
-231 9 l-36 -24 0 29 c0 29 -2 30 -45 30 l-45 0 0 -210z"/>
<path d="M6022 578 c3 -131 6 -158 23 -186 50 -84 172 -106 259 -47 l36 24 0
-29 c0 -29 2 -30 45 -30 l45 0 0 210 0 210 -49 0 -49 0 -4 -141 c-3 -140 -3
-141 -31 -165 -40 -34 -118 -34 -152 1 -24 23 -25 29 -25 165 l0 140 -51 0
-51 0 4 -152z"/>
<path d="M6550 520 l0 -210 50 0 50 0 0 131 c0 116 2 134 21 160 26 37 87 55
135 39 58 -19 64 -38 64 -192 l0 -138 51 0 51 0 -4 153 c-4 169 -13 202 -70
237 -67 40 -186 32 -240 -17 -17 -15 -18 -14 -18 15 0 31 -1 32 -45 32 l-45 0
0 -210z"/>
<path d="M7090 520 l0 -210 50 0 50 0 0 210 0 210 -50 0 -50 0 0 -210z"/>
<path d="M7455 721 c-82 -20 -135 -73 -155 -153 -27 -106 33 -210 139 -242 64
-19 92 -20 144 -5 36 10 107 62 107 79 0 4 -15 17 -34 28 -31 18 -36 19 -47 6
-35 -42 -99 -54 -153 -29 -60 29 -85 118 -51 184 32 63 142 79 193 28 l24 -25
34 20 c19 11 34 24 34 28 0 4 -16 22 -35 38 -52 46 -123 61 -200 43z"/>
<path d="M7845 714 c-71 -22 -81 -34 -61 -73 16 -30 18 -30 40 -16 28 18 113
30 150 21 33 -8 66 -40 66 -65 0 -16 -11 -19 -97 -23 -117 -6 -158 -23 -180
-75 -19 -46 -10 -93 24 -127 50 -51 159 -58 230 -16 l33 19 0 -24 c0 -23 4
-25 46 -25 l46 0 -4 153 c-3 174 -15 208 -82 241 -51 25 -148 30 -211 10z
m195 -247 c0 -46 -36 -78 -94 -84 -59 -7 -96 15 -96 56 0 47 24 58 128 60 l62
1 0 -33z"/>
<path d="M8590 520 l0 -210 50 0 50 0 0 210 0 210 -50 0 -50 0 0 -210z"/>
<path d="M8955 721 c-158 -41 -216 -226 -106 -341 89 -93 269 -87 345 12 76
101 45 253 -64 308 -44 22 -130 33 -175 21z m119 -86 c41 -20 66 -63 66 -115
0 -55 -22 -94 -64 -114 -93 -44 -186 13 -186 114 0 100 93 158 184 115z"/>
<path d="M9320 520 l0 -210 50 0 50 0 0 131 c0 116 2 134 21 160 26 37 87 55
135 39 58 -19 64 -38 64 -192 l0 -138 51 0 51 0 -4 153 c-3 139 -5 155 -26
190 -28 45 -66 66 -130 73 -51 6 -122 -14 -154 -43 -17 -15 -18 -14 -18 15 0
31 -1 32 -45 32 l-45 0 0 -210z"/>
<path d="M9925 712 c-63 -22 -99 -70 -91 -121 10 -63 54 -90 171 -107 28 -3
58 -11 68 -16 23 -12 22 -55 -2 -68 -31 -16 -116 -12 -170 9 l-49 18 -16 -34
c-9 -18 -16 -35 -16 -38 0 -14 115 -40 180 -39 89 0 143 22 171 68 51 83 -1
151 -131 171 -84 12 -110 24 -110 50 0 35 32 48 104 42 33 -3 71 -8 83 -12 17
-6 25 -1 38 25 20 39 15 44 -58 60 -67 14 -114 12 -172 -8z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
